import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewChild,
    ViewRef,
} from "@angular/core";

import { Task } from "../../pojo/Task";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { TaskService } from "../../services/task/task.service";
import { Table } from "../s25-table/Table";
import { Bind } from "../../decorators/bind.decorator";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { CacheRepository } from "../../decorators/cache.decorator";
import { S25CheckboxComponent } from "../s25-checkbox/s25.checkbox.component";
import { S25EditableTextareaComponent } from "../s25-editable/s25-editable-textarea/s25.editable.textarea.component";
import { S25EditableDateComponent } from "../s25-editable/s25-editable-date/s25.editable.date.component";
import { S25TaskEditComponent } from "../s25-task/s25.task.edit.component";
import { S25TaskAssignComponent } from "../s25-task/s25.task.assign.component";
import { Api } from "../../api/api";
import { ContactService } from "../../services/contact.service";
import { TaskViewApi } from "../s25-task-view/s25.task.view.component";
import { jSith } from "../../util/jquery-replacement";
import TaskList = Task.TaskListI;
import { S25TaskActionComponent } from "./s25.task.action.component";
import { S25TaskStatusComponent } from "./s25.task.status.component";
import { TaskNormalizeUtil } from "../../services/task/task.normalize.util";
import { OptBean } from "../s25-opt/s25.opt.component";
import TaskListTaskItem = Task.TaskListTaskItem;

export class EventTaskWorkListApi extends Api {
    static refresh(relativeElem: HTMLElement) {
        return Api.callApiFn(relativeElem, "s25-ng-event-task-list", null, null, (comp: S25EventTaskListComponent) => {
            comp.refreshF();
        });
    }
}
export interface TaskViewModelI {
    optBean: OptBean;
    messages: string;
    isInit: boolean;
    getRow: Task.TaskListI[];
    workflowChained?: boolean;
    optBeanRunTime?: any;
}
@TypeManagerDecorator("s25-ng-event-task-list")
@Component({
    selector: "s25-ng-event-task-list",
    template: `
        @if (init) {
            <div class="task-wrapper">
                <div class="c-margin-bottom--half">
                    @if (initTaskList && taskList.length > 0) {
                        <s25-ng-task-buttons
                            [modelBean]="selectedItems"
                            (onRefresh)="onTaskButtonRefresh()"
                        ></s25-ng-task-buttons>
                    }
                    <s25-ng-table
                        [dataSource]="tableConfig"
                        [columnSortable]="true"
                        [hasFilter]="false"
                        [hasRefresh]="false"
                        [pivotThreshold]="800"
                        [hasSelect]="hasSelect"
                        [hasSelectAll]="hasSelectAll"
                        [noResultsMessage]="'No Tasks Found'"
                        (selectedChange)="selectedChange($event)"
                    ></s25-ng-table>
                    @if (initTaskList && taskList.length > 0) {
                        <s25-ng-task-buttons
                            [modelBean]="selectedItems"
                            (onRefresh)="onTaskButtonRefresh()"
                        ></s25-ng-task-buttons>
                    }
                </div>
            </div>
        }
    `,
    styles: `
        ::ng-deep s25-ng-event-task-list tbody tr td input[type="checkbox"] {
            height: 16px !important;
        }

        ::ng-deep s25-ng-event-task-list s25-item-generic {
            color: rgb(37, 115, 167);
        }

        ::ng-deep s25-ng-event-task-listt s25-item-generic s25-popover > .ngInlineBlock {
            display: block !important;
        }

        ::ng-deep s25-ng-event-task-list .s25-ng-table--cell.stateTentative {
            font-style: italic;
        }

        ::ng-deep s25-ng-event-task-list .s25-ng-table--cell.stateCancelled {
            color: #d62000;
            font-weight: 400;
        }

        ::ng-deep .nm-party--on s25-ng-event-task-list .s25-ng-table--cell.stateCancelled {
            color: #ff837a;
        }
        ::ng-deep s25-ng-event-task-list s25-ng-table .italic {
            font-style: italic;
        }

        ::ng-deep s25-ng-event-task-list s25-ng-task-edit > span {
            display: block !important;
        }

        ::ng-deep ss25-ng-event-task-list s25-ng-table s25-ng-editable-date .editable-input {
            width: 100% !important;
        }

        ::ng-deep s25-ng-event-task-list s25-ng-table thead th.s25TableColumn {
            color: #000000cc !important;
            font-size: 14px;
            padding: 1em 0.5em !important;
        }

        ::ng-deep .nm-party--on s25-ng-event-task-list s25-ng-table thead th.s25TableColumn {
            color: #fff !important;
        }

        ::ng-deep s25-ng-event-task-list s25-ng-table .s25-ng-table--table {
            border: 1px solid #e5e5e5;
        }

        ::ng-deep s25-ng-event-task-list s25-ng-table tbody tr:not(:last-of-type) {
            border-bottom: 1px solid #e5e5e5;
        }

        ::ng-deep s25-ng-event-task-list s25-ng-table .s25-ng-table--header:not(:first-child),
        ::ng-deep s25-ng-event-task-list s25-ng-table .s25-ng-table--cell:not(:first-child) {
            border-left: 1px solid #e5e5e5;
        }

        ::ng-deep .nm-party--on s25-ng-event-task-list s25-ng-table .s25-ng-table--table {
            border: 1px solid rgba(255, 255, 255, 0.24);
        }

        ::ng-deep .nm-party--on s25-ng-event-task-list s25-ng-table tbody tr:not(:last-of-type) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.24);
        }

        ::ng-deep .nm-party--on s25-ng-event-task-list s25-ng-table .s25-ng-table--header:not(:first-child),
        ::ng-deep .nm-party--on s25-ng-event-task-list s25-ng-table .s25-ng-table--cell:not(:first-child) {
            border-left: 1px solid rgba(255, 255, 255, 0.24);
        }

        ::ng-deep #s25 s25-ng-event-task-list .button-group {
            margin-left: 0.5em !important;
        }

        ::ng-deep #s25 s25-ng-event-task-list .c-label--checkbox {
            margin-left: 0 !important;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EventTaskListComponent implements OnInit {
    @Input() modelBean: TaskViewModelI;

    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    tableConfig: Table.DataSource;
    init: boolean;
    userId: number;
    initTaskList: boolean;
    taskList: TaskList[] = [];
    hasSelect: boolean = false;
    hasSelectAll: boolean = false;

    columns: Table.Column[] = [
        { id: "reference", header: "Reference", sortable: true },
        { id: "name", header: "Task Item", sortable: true },
        { id: "type", header: "Type", sortable: true },
        { id: "status", header: "Status", sortable: true },
        { id: "flag", header: "Flagged", sortable: false, align: "center", width: 60 },
        { id: "responseBy", header: "Respond By", sortable: true },
        { id: "firstDate", header: "First Date", sortable: true },
        { id: "action", header: "Action", sortable: false, minWidth: 50 },
        { id: "assignTo", header: "Assign To", sortable: true },
        { id: "comments", header: "Comments", sortable: true },
    ];

    selectedItems: { eventId: number; tasks: TaskListTaskItem[] };

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit() {
        let find = this.columns.filter((i: Table.Column) => {
            return i.id === "reference";
        });
        if (!this.modelBean.optBean.showRelatedEvents) {
            find && find.length > 0 ? this.columns.splice(0, 1) : "";
        } else {
            find.length === 0
                ? this.columns.unshift({ id: "reference", header: "Reference", sortable: false, width: 95 })
                : "";
        }

        this.modelBean.messages = null; //no error message to show
        this.modelBean.isInit = true;

        this.tableConfig = {
            type: "unpaginated",
            columns: this.columns,
            dataSource: this.getEventTasks,
        };

        this.init = true;
        this.detectChanges();
    }

    @Bind
    async getEventTasks(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByService("TaskListServiceGetEventTasks"); // When user refreshes invalidate cache
        let data = this.modelBean.getRow;
        this.taskList = data;

        if (this.taskList.length > 0) {
            this.hasSelect = true;
            this.hasSelectAll = true;
            this.taskList = this.taskList.sort((a, b) => {
                const dateA = new Date(a.eventDate);
                const dateB = new Date(b.eventDate);
                return dateA.getTime() - dateB.getTime();
            });
        }

        const configs = data.map(this.mapConfigToRow);
        this.initTaskList = true;

        return {
            rows: configs,
            totalRows: configs.length,
        };
    }

    mapConfigToRow = (task: TaskList): Table.Row => {
        let targets = task.assignedTo.itemName
            .split(";")
            .filter((target: any) => target !== " ")
            .map((target: any) => target);
        const contactNames: Table.Cell[] = targets.map((target: any) => ({ text: target }));

        //Want to still have contact list on the task item since the add assignees functionality needs it for security check
        task.taskItem.contacts = TaskNormalizeUtil.eventTaskContactToObj(task.assignedTo?.task?.approval_contact);

        if (task.actions.isTodo) {
            task.actions.assignedById === task.currentUserId || task.actions.assignedToId === task.currentUserId
                ? (task.readOnly = false)
                : (task.readOnly = true);
        } else {
            task.readOnly = !task.hasApproveDenyPerms;
        }
        return {
            id: task.actions.itemId,
            name: task.actions.taskName,
            cells: {
                reference: { text: task.reference },
                name: {
                    component: S25TaskEditComponent,
                    inputs: { taskModel: task.taskItem, showIcon: true, taskBlocked: false },
                    outputs: {
                        onRefresh: (val: string, row: Table.Row) => {
                            let el: any = document;
                            TaskViewApi.reset(el);
                        },
                    },
                    textValue: task.actions.taskName,
                },
                type: { text: task.type },
                status: {
                    component: S25TaskStatusComponent,
                    inputs: {
                        task: {
                            task_blocked: task.actions.taskBlocked,
                            task_state: task.status.itemStateId === 3 ? 3 : task.actions.itemStateId, // If denied for anyone, then denied for everyone
                            task_type: task.actions.itemTypeId,
                        },
                    },
                    textValue: task.status.itemName,
                },
                action: {
                    component: S25TaskActionComponent,
                    inputs: {
                        assignedToId: task.actions.assignedToId,
                        taskState: task.actions.itemStateId, // InProgress = 1, Completed = 2....
                        taskId: task.actions.itemId,
                        taskBlocked: task.actions.taskBlocked,
                        itemCount: task.actions.itemCount,
                        taskType: task.actions.itemTypeId, //  FYI = 1, Authorization = 2.....
                        requesterId: task.actions.requesterId,
                        eventId: task.actions.eventId,
                        workflowChained: task.actions.workflowChained,
                        todoType: task.taskItem.todoType,
                        todoSubType: task.taskItem.todoSubType,
                    },
                    outputs: { stateChange: this.onTaskAction },
                },
                flag: {
                    component: S25CheckboxComponent,
                    inputs: {
                        modelValue: task.flagged.value,
                        noLabel: true,
                        ariaLabel: `${task.flagged.value ? "Unset" : "Set"} flagged for ${task.actions.taskName}`,
                    },
                    outputs: {
                        modelValueChange: (val: boolean, row: Table.Row) => {
                            TaskService.setFlag(task.actions.itemTypeId, task.actions.itemId, val);
                        },
                    },
                },
                responseBy: {
                    component: S25EditableDateComponent,
                    inputs: { val: task.respondBy.date, readOnly: !task.hasApproveDenyPerms },
                    outputs: {
                        valChange: (val: string, row: Table.Row) => {
                            if (task.actions.isTodo) {
                                TaskService.putTodoDueDate(task.actions.itemId, val);
                            } else {
                                TaskService.putEventTaskDueDate(task.actions.eventId, task.actions.itemId, val);
                            }
                        },
                    },
                    textValue: task.respondBy.date.toString(),
                    sortValue: S25Util.date.toS25ISODateTimeStr(task.respondBy.date),
                },
                firstDate: {
                    component: S25EditableDateComponent,
                    inputs: { val: task.eventDate, readOnly: true },
                    outputs: {
                        valChange: (val: string, row: Table.Row) => {
                            //just display date, do nothing here
                        },
                    },
                    textValue: task.eventDate.toString(),
                    sortValue: S25Util.date.toS25ISODateTimeStr(task.eventDate),
                },
                assignTo: {
                    component: S25TaskAssignComponent,
                    inputs: {
                        items: contactNames,
                        taskItem: task.taskItem,
                        approval: task?.assignedTo?.task,
                        taskType: task.actions.itemTypeId, //  FYI = 1, Authorization = 2.....
                    },
                    textValue: task.assignedTo.itemName,
                },
                comments: {
                    component: S25EditableTextareaComponent,
                    inputs: { val: task.comments.value, readOnly: task.readOnly },
                    outputs: {
                        valChange: (val: string, row: Table.Row) => {
                            if (task.actions.isTodo) {
                                TaskService.putTodoComment(task.actions.itemId, val);
                            } else {
                                TaskService.putEventTaskComment(task.actions.eventId, task.actions.itemId, val);
                            }
                        },
                    },
                    textValue: task.comments.value,
                },
            },
        };
    };

    @Bind
    onTaskAction(state: Task.State, row: Table.Row) {
        // Update task item column
        const taskItem = row.cells.name.instance as S25TaskEditComponent;
        if (taskItem) {
            taskItem.taskModel.itemStateId = state;
            taskItem.refresh();
        }
        // Update status column
        const status = row.cells.status.instance as S25TaskStatusComponent;
        if (status) {
            status.task.task_state = state;
            status.refresh();
        }
    }

    private getRowsByTaskId(taskIds: number[]) {
        return this.modelBean.getRow.filter((f: any) => {
            return taskIds.includes(f.actions.itemId);
        });
    }

    selectedChange(selectItem: Set<Table.Row["id"]>) {
        let selectedItem: number[] = Array.from(selectItem) as number[];
        let ret: number[] = [];
        let count = 0;
        //check select item has hasApproveDenyPerms
        jSith.forEach(selectedItem, (key, i) => {
            let find = this.modelBean.getRow.find((f: any) => {
                return i && f.hasApproveDenyPerms === true && f.actions.itemId === i;
            });
            if (find) {
                ret.push(i);
            } else {
                count++, this.tableComponent.selected.delete(i);
            }
            return ret;
        });

        this.selectedItems = {
            eventId: this.modelBean.getRow[0].taskItem.eventId,
            tasks: this.getRowsByTaskId(ret).map((row) => row.taskItem),
        };

        count > 0 ? alert("You do not have permission to approve or deny on one or more of the selected tasks!") : "";
    }

    async successF() {
        await S25Util.delay(250);
        this.detectChanges();
    }

    async refreshF() {
        await this.resetF();
    }

    async onTaskButtonRefresh() {
        await this.resetF();
    }

    async resetF() {
        this.init = false;
        this.detectChanges();
        await S25Util.delay(250);
        this.ngOnInit();
    }

    getData() {
        return Promise.all([ContactService.getCurrentName(), ContactService.getCurrentId()]).then(
            ([userName, userId]) => {
                return { userName, userId };
            },
        );
    }

    detectChanges() {
        try {
            setTimeout(() => {
                this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
            }, 60);
        } catch (error: any) {}
    }
}
